import React from "react";
import { Form } from "react-bootstrap";
export const CreateAgentText: React.FC<{
  label: string;
  register: any;
  type: string;
  error?: any;
}> = (props) => {
  return (
    <Form.Group className="col-md-6">
      <Form.Label>{props.label}</Form.Label>
      <Form.Control
        {...props.register}
        type={props.type}
        isInvalid={Boolean(props.error)}
      />
      <Form.Control.Feedback type="invalid">
        {props?.error?.message}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

export const RadioInp: React.FC<{
  register: any;
  label: any;
  options: Array<{
    id: string;
    value: string;
    label: string;
  }>;
}> = (props) => {
  return (
    <>
      <Form.Group className="col-md-6 ">
        <Form.Label className="d-block mt-2 mb-0">{props.label}</Form.Label>
        <div className=" d-flex justify-content-left ">
          {props.options.map((option, index) => (
            <Form.Check
              style={{ marginRight: "10px" }}
              key={index}
              {...props.register}
              type="radio"
              label={option.label}
              value={option.value}
              id={option.id}
            />
          ))}
        </div>
      </Form.Group>
    </>
  );
};

export const SelectInp: React.FC<{
  label: string;
  error: any;
  register: any;
  options: Array<{
    label: string;
    value: any;
  }>;
}> = (props) => {
  return (
    <>
      <Form.Group className="col-md-6">
        <Form.Label>{props.label}</Form.Label>
        <Form.Select {...props.register} isInvalid={Boolean(props.error)}>
          {props.options.map((option, index) => (
            <option value={option.value} key={index}>
              {option.label}
            </option>
          ))}
        </Form.Select>
        <Form.Control.Feedback type="invalid">
          {props?.error?.message}
        </Form.Control.Feedback>
      </Form.Group>
    </>
  );
};
