import { TUserPendingFormValues } from "../../types/userPending.types";

export const validateUserDetailsSchema = (data: TUserPendingFormValues) => {
  let errors: any;
  let errorMessage: string | null = null;

  if (!(data.phone || data.email)) {
    errorMessage = "Either phone or email is required.";
  }

  return { errors, errorMessage };
};
