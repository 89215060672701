import { useLocation, useParams } from "react-router-dom";
import PackageEnquirySuccessPageStyle from "./PackageEnquirySuccessPageStyle.module.scss";
import { getAuthToken } from "tools/functions";

export const PackageEnquirySuccessPage = () => {
  const authToken = getAuthToken();
  const location = useLocation();
  const params = useParams<{ enquiryId: string }>();
  const queryParams = new URLSearchParams(location.search);

  const enquiryId = params.enquiryId;
  const title = queryParams.get("title");
  return (
    <div id={PackageEnquirySuccessPageStyle.successEnquiryPage}>
      <div className="container text-center">
        <i className="far fa-thumbs-up text-success"></i>
        <h1 className="text-success">Success!</h1>
        <p>
          Your package enquiry has been successfully submitted.{" "}
          <b>ID: {enquiryId}</b>
        </p>
        {title && (
          <>
            {" "}
            <p>
              <strong>Enquiry Title:</strong> {decodeURIComponent(title)}
            </p>
            <p className="mt-3">
              Our team will reach out to you shortly. Thank you for your
              enquiry!
            </p>
          </>
        )}
        {authToken && (
          <div className="mt-4">
            <button
              className="btn btn-sm btn-primary rounded"
              onClick={() => {
                window.location.href = "/crm";
              }}
            >
              Go To All Enquiries
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
