import CreateNewAgent from "components/common/createNewAgent/CreateNewAgent";
import { useEffect, useRef, useState } from "react";

export const CreateNewUserPage = () => {
  let timeOutIdRef: any = useRef();

  useEffect(() => {
    return () => {
      clearTimeout(timeOutIdRef.current);
    };
  }, []);
  return (
    <div className="container p-4">
      <h3>Create New User</h3>
      <CreateNewAgent
        handleClose={() => {
          timeOutIdRef.current = setTimeout(() => {
            window.close();
          }, 1500);
        }}
      />
    </div>
  );
};
