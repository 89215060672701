import Joi from "joi";
import { z } from "zod";

const loginDetailsSchema = Joi.object({
  email: Joi.string().required().label("Email"),
  password: Joi.string().allow("").label("Password"),
  status: Joi.string().required().label("status"),
})
  .required()
  .label("Login Details");

export enum userTypeEnum {
  retail = "retail",
  travelAgent = "travelAgent",
  corporate = "corporate",
}
const billingDetailsSchema = Joi.object({
  // billingName: Joi.string().label("Billing Name").allow(""),
  panNumber: Joi.string().allow(""),

  gstNumber: Joi.string().allow("").label("GST Number"),
  gstState: Joi.string().allow("").label("GST State"),
}).label("Billing details");

export const b2cUserSchema = Joi.object({
  userType: Joi.string().required().label("User Type"),
  loginDetails: loginDetailsSchema,
  billingDetails: billingDetailsSchema,
  customer: Joi.object({
    gender: Joi.string().required().label("Label"),
    firstName: Joi.string().required().label("First Name"),
    lastName: Joi.string().required().label("Last Name"),
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .required()
      .label("Email"),
    phone: Joi.string().label("Phone").required(),
    phoneCode: Joi.string().label("Phone Code").required(),
  }).required(),
  // below line has been added becuase could not handle react hook form error. it was sending company by default as empty {}
  //could not remove company object from react-hook-form's internal state
  company: Joi.object().optional().label("Company"),
  referralCode: Joi.string().allow("").optional().label("Referral Code"),
});

export const b2bUserSchema = Joi.object({
  userType: Joi.string().label("User Type"),
  billingDetails: billingDetailsSchema,
  originalStaffUserId: Joi.number().optional().allow(null),
  company: Joi.object({
    companyName: Joi.string().required().label("Company Name"),
    primaryEmail: Joi.string().required().label("Primary Email"),
    primaryPhone: Joi.string().required().label("Primary Phone").required(),
    primaryPhoneCode: Joi.string().required().label("Primary Phone Code"),
    secondaryPhone: Joi.string().required().label("Secondary Phone"),
    secondaryPhoneCode: Joi.string().required().label("Secondary Phone Code"),
    concernedPersonFirstName: Joi.string()
      .label("Concerned Person First Name")
      .required(),
    concernedPersonLastName: Joi.string()
      .label("Concerned Person Last Name")
      .allow("")
      .required(),
    // registeredOfficeAddress: Joi.string(),
    country: Joi.string().required().label("Country"),
    // changed the name of state to StateX bcz "state" nmae wasn't working in react
    StateX: Joi.string().required().label("State"),
    city: Joi.string().required().label("City"),
    pincode: Joi.string().required().label("Pincode"),
    address1: Joi.string().required().label("Address 1"),
    address2: Joi.string().allow("").label("Address 2"),
  }).required(),
  // below line has been added becuase could not handle react hook form error. it was sending customer by default as empty {}
  //could not remove customer object from react-hook-form's internal state
  customer: Joi.object().optional().label("Customer"),
  loginDetails: loginDetailsSchema,
  referralCode: Joi.string().allow("").optional().label("Referral Code"),
});

export const detailPendingUserSchema = Joi.object({
  userType: Joi.string().required().label("User Type"),
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .label("Email")
    .optional()
    .allow(""),
  firstName: Joi.string().required().label("First Name"),
  lastName: Joi.string().optional().label("Last Name").allow(""),
  phone: Joi.string().optional().label("Phone").allow(""),
  companyName: Joi.string().optional().allow("").label("Company Name"),
  panNumber: Joi.string().label("PAN").allow(""),
  gst: Joi.string().allow("").label("GST"),
  facebookUserId: Joi.string().optional(),
  phoneCode: Joi.string().optional().label("Phone Code").allow(""),
}).required();

export const staffSchema = z.object({
  id: z.number().optional(),
  firstName: z.string(),
  lastName: z.string(),
  designation: z.string(),
  email: z.string(),
  // phoneCode: z.string(),
  // phone: z.string(),
  // password: z.string().optional(),
  site_id: z.string(),
  isSuperAdmin: z.boolean().optional(),
  staff: z.object({
    branchId: z.number(),
  }),
});

export type TStaffDetail = z.infer<typeof staffSchema>;
