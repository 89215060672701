import { joiResolver } from "@hookform/resolvers/joi";
import {
  userPartialSignup,
  userPartialSignupForDmc,
  userSignup,
} from "api/user";
import {
  b2cUserSchema,
  detailPendingUserSchema,
} from "commonFnBcode/joiSchemas/UserSchemas";
import { validateUserDetailsSchema } from "commonFnBcode/libs/validations/user.validation";
import { FullScreenLoader } from "components/afterLogin/dashboard/flightApis/AirlineDiscountMarkup/components/FullScreenLoader";
import { IntlInp } from "components/signup/SignupHelperComponents";
import React, { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useForm, UseFormSetError } from "react-hook-form";
import { toast } from "react-toastify";
import {
  TUserPendingFormValues,
  UserTypeEnum,
} from "../../../commonFnBcode/types/userPending.types";
import { CreateAgentText, RadioInp } from "./CreateAgentHelper";

const CreateNewAgent: React.FC<{
  handleClose: () => void;
}> = (props) => {
  const [loadingState, setLoadingState] = useState(false);

  const [phoneCodeState, setPhoneCodeState] = useState<string>("");

  const {
    handleSubmit,
    register,
    formState,
    control,
    setError,
    clearErrors,
    reset,
  } = useForm<TUserPendingFormValues>({
    resolver: joiResolver(detailPendingUserSchema),
    defaultValues: {
      userType: UserTypeEnum.TRAVEL_AGENT,
      companyName: "",
      firstName: "",
      lastName: "",
      email: "",
      gst: "",
      panNumber: "",
      phone: "",
    },
  });
  const errors = formState.errors;

  const onSubmit = async (data) => {
    try {
      const { errors, errorMessage } = validateUserDetailsSchema(data);

      if (errorMessage) {
        toast.error(errorMessage);
        return;
      }
      setLoadingState(true);

      const signupResponse = await userPartialSignupForDmc({
        userData: {
          customer: { ...data, phoneCode: `+${phoneCodeState}` },
          userType: data.userType,
        },
      });
      if (signupResponse.data.success) {
        toast.success("Agent Created Successfully");
        reset();
        props.handleClose();
      } else {
        const errorKeys = Object.keys(signupResponse.error.shortError);
        if (errorKeys.length > 0) {
          errorKeys.forEach((key: any) => {
            setError(key, {
              type: "manual",
              message: signupResponse.error.shortError[key],
            });
          });

          throw new Error(signupResponse.errorMessage);
        }
      }
    } catch (err: any) {
      console.log(err.message);
    }
    setLoadingState(false);
  };

  return (
    <div>
      <FullScreenLoader show={loadingState} />
      {/* create Agent modal  starts */}

      <form>
        <div className="row">
          <Form.Group className="col-12">
            <RadioInp
              label="Agent Type"
              register={register("userType")}
              options={[
                { id: "AgentTypeB2c", label: "B2c", value: "retail" },
                {
                  id: "AgentTypeB2c",
                  label: "B2B",
                  value: "travelAgent",
                },
              ]}
            />
          </Form.Group>

          <CreateAgentText
            label="First Name"
            register={register("firstName")}
            type="text"
            error={errors.firstName}
          />
          <CreateAgentText
            label="Last Name"
            register={register("lastName")}
            type="text"
            error={errors.lastName}
          />

          <CreateAgentText
            label="Email"
            register={register("email")}
            type="text"
            error={errors.email}
          />
          <IntlInp
            name="phone"
            control={control}
            placeholder="Phone"
            onCountryChange={(props) => {
              setPhoneCodeState(props.target.value);
            }}
            handleNumberChange={(props) => {}}
            error={errors?.phone || undefined}
            setError={setError}
            clearErrors={clearErrors}
          />

          <CreateAgentText
            label="Company Name"
            register={register("companyName")}
            type="text"
            error={errors.companyName}
          />
          <CreateAgentText
            label="Pan Number"
            register={register("panNumber")}
            type="text"
            error={errors.panNumber}
          />
          <CreateAgentText
            label="GST"
            register={register("gst")}
            type="text"
            error={errors.gst}
          />

          {/* <Form.Group className="col-12">
              <Form.Text>Signup notification/welcome Email</Form.Text>
              <Form.Check
                type="checkbox"
                label="Send Notification"
                id="sendNotificationEmail"
              />
            </Form.Group> */}
        </div>
        <div className="mt-4 ">
          <div className="row ">
            <div className="col-12 d-flex justify-content-end">
              <Button variant="secondary" onClick={props.handleClose}>
                Close
              </Button>
              <Button
                variant="primary"
                className="ms-2"
                type="button"
                onClick={handleSubmit(onSubmit)}
              >
                Create Agent
              </Button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default CreateNewAgent;
