import React from "react";
import { Form, OverlayTrigger, Popover } from "react-bootstrap";
import IntlTelInput, { CountryData } from "react-intl-tel-input";
import { Controller } from "react-hook-form";
import "react-intl-tel-input/dist/main.css";
export const SignupText = (props: {
  label: string;
  register: any;
  type: string;
  required?: boolean;
  error?: any;
  className?: string;
  children?: React.ReactNode;
  popoverText?: string;
  isDisabled?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}) => {
  return (
    <Form.Group className="mb-3">
      <Form.Label className="mb-0">
        {props.label}
        {props.required && <span className="text-danger">*</span>}
      </Form.Label>
      {props.popoverText && (
        <OverlayTrigger
          key="bottom"
          placement="bottom"
          overlay={
            <Popover id={`popover-positioned-bottom`}>
              <Popover.Body>{props.popoverText}</Popover.Body>
            </Popover>
          }
        >
          <i className="ms-1 fas fa-info-circle"></i>
        </OverlayTrigger>
      )}
      <Form.Control
        {...props.register}
        type={props.type}
        isInvalid={Boolean(props.error)}
        disabled={props.isDisabled || false}
        onChange={props.onChange || props.register.onChange}
      />

      <Form.Control.Feedback type="invalid">
        {props?.error?.message}
      </Form.Control.Feedback>
      {props.children}
    </Form.Group>
  );
};

// for intlinput
const excludedCountriesIntelInput = ["pk", "af", "iq", "ir", "kp", "ps"];

export const IntlUncontrolledInput: React.FC<{
  defaultValue?: string;
  placeholder: string;
  required: boolean;
  error?: any;
  setError: any;
  clearErrors: any;
  setValue: any;
  // setPhoneCode: any;
  name: string;
  phoneCodeName: string;
  // handlePhoneNumberChange?: (isValid: boolean, value: string, selectedCountryData: CountryData, fullNumber: string, extension: string) => void;
}> = (props) => {
  return (
    <Form.Group className="mb-3">
      <Form.Label className="mb-1">
        {props.placeholder}{" "}
        {props.required && <span className="text-danger">*</span>}
      </Form.Label>

      <IntlTelInput
        preferredCountries={["in"]}
        excludeCountries={excludedCountriesIntelInput}
        containerClassName="intl-tel-input w-100"
        defaultValue={props.defaultValue}
        // customPlaceholder={() => label}
        inputClassName="form-control m-0 w-100"
        style={{ width: "100%" }}
        onPhoneNumberChange={(
          isValid,
          value,
          selectedCountry,
          fullNumber,
          extension
        ) => {
          console.log({
            isValid,
            fullNumber,
            selectedCountry,
          });
          if (isValid) {
            props.setValue(props.name, value.replace(/ /g, ""));
            props.clearErrors(props.name);
            props.clearErrors(props.phoneCodeName);
            props.setValue(props.phoneCodeName, `+${selectedCountry.dialCode}`);
          } else {
            props.setError(props.name, {
              message: "Phone number is required!",
              type: "manual",
            });
          }
        }}
      />
      <div className="text-danger mt-1" style={{ fontSize: "0.875em" }}>
        {props?.error?.message}
      </div>
    </Form.Group>
  );
};

export const IntlInp: React.FC<{
  name: string;
  control: any;
  // rules: any;
  placeholder: string;
  error?: any;
  setError: any;
  required?: boolean;
  onCountryChange?: (props: {
    target: {
      name: string;
      value: string;
    };
  }) => void;
  handleNumberChange: (props: {
    target: {
      name: string;
      value: string;
    };
  }) => void;
  clearErrors: any;
}> = (props) => {
  return (
    <>
      <Form.Group className="col-md-6">
        <Form.Label>
          {props.placeholder}{" "}
          {props.required && <span className="text-danger">*</span>}
        </Form.Label>
        <Controller
          name={props.name}
          control={props.control}
          // rules={props.rules||{}}
          render={({
            field: { onChange, onBlur, value, name, ref },
            // fieldState: { invalid, isTouched, isDirty, error },
            // formState,
          }) => (
            <IntlTelInput
              preferredCountries={["in"]}
              customPlaceholder={() => props.placeholder}
              containerClassName="intl-tel-input"
              inputClassName="form-control m-0 w-100"
              style={{ width: "100%" }}
              excludeCountries={excludedCountriesIntelInput}
              onPhoneNumberChange={(
                isValid,
                value,
                selectedCountry,
                fullNumber
                // formattedNumber,
                // extention
              ) => {
                if (isValid) {
                  if (props.onCountryChange && selectedCountry.dialCode) {
                    props.onCountryChange({
                      target: {
                        name: props.name,
                        value: selectedCountry.dialCode,
                      },
                    });
                  }
                  onChange(`${value}`);
                  props.handleNumberChange({
                    target: {
                      name: props.name,
                      value: `${value}`,
                    },
                  });
                  props.clearErrors(props.name);
                } else {
                  props.setError(props.name, {
                    type: "manual",
                    message: "Invalid Phone Number...",
                  });
                }
              }}
            />
          )}
        />
        <div className="text-danger mt-1" style={{ fontSize: "0.875em" }}>
          {props?.error?.message}
        </div>
      </Form.Group>
    </>
  );
};

export const RadioInp: React.FC<{
  register: any;
  label: any;
  options: Array<{
    id: string;
    value: string;
    label: string;
  }>;
}> = (props) => {
  return (
    <>
      <Form.Group className="mb-3 d-flex">
        <Form.Label className="mb-0 me-3">{props.label}</Form.Label>
        <div className="d-flex ">
          {props.options.map((option, index) => (
            <Form.Check
              style={{ marginRight: "10px" }}
              key={index}
              {...props.register}
              type="radio"
              label={option.label}
              value={option.value}
              id={option.id}
            />
          ))}
        </div>
      </Form.Group>
    </>
  );
};

export const SelectInp: React.FC<{
  label: string;
  error: any;
  register: any;
  required?: boolean;
  options: Array<{
    label: string;
    value: any;
  }>;
}> = (props) => {
  return (
    <>
      <Form.Group className="mb-3">
        <Form.Label className="mb-0">
          {props.label}
          {props.required && <span className="text-danger">*</span>}
        </Form.Label>
        <Form.Select {...props.register} isInvalid={Boolean(props.error)}>
          <option>Select</option>
          {props.options.map((option, index) => (
            <option value={option.value} key={index}>
              {option.label}
            </option>
          ))}
        </Form.Select>
        <Form.Control.Feedback type="invalid">
          {props?.error?.message}
        </Form.Control.Feedback>
      </Form.Group>
    </>
  );
};
